<template>
  <div class="index-container flex">
    <div class="left mr-10 w-890">
      <div class="left-top mb-10 rounded-[10px] bg-white">
        <div class="flex justify-between border-b border-[#EFF1F5] p-20">
          <div class="flex w-400 justify-between">
            <div>
              <div class="mb-12 flex items-center">
                <i class="iconfont icon-a-6zhishu text-20 text-gray-light"></i>
                <span class="mx-5 text-16 font-[500]">{{ $t('饰品指数') }}</span>
                <mytooltip :class="'w-350'" placement="bottom">
                  <i class="iconfont icon-kongxinwenhao text-18"></i>

                  <template #content>
                    <div class="py-15 text-14 text-gray-light">
                      <p>{{ $t('饰品指数：反映饰品市场的价格行情') }}</p>
                      <p>{{ $t('指数的起始点为1000，起始时间为2023年6月20日，每5分钟计算一次大盘指数。') }}</p>
                      <p>{{ $t('计算方法：') }}</p>
                      <p>{{ $t('筛选出真实反映市场趋势的皮肤，以皮肤的全网在售数量赋予相应权重') }}</p>
                      <p>{{ $t('起始市值总和 = 累加每个皮肤起始最低售价*权重') }}</p>
                      <p>{{ $t('今日指数 = 1000点 * 今日市值总和 / 起始市值总和') }}</p>
                    </div>
                  </template>
                </mytooltip>

                <div class="ml-10 flex items-center justify-center rounded-[4px] px-5 text-white" :class="getTopClass('marketIndexTrend')">
                  <i v-if="summery.marketIndexTrend.riseFallType === 'UP'" class="iconfont icon-shangsheng1 text-12 text-white"></i>
                  <i v-if="summery.marketIndexTrend.riseFallType === 'DOWN'" class="iconfont icon-xiajiang text-12 text-white"></i>
                  <span class="text-white">{{ getTopText('marketIndexTrend') }}</span>
                </div>
              </div>
              <div class="text-red">
                <span class="mr-5 text-26 font-[500]" :class="summery.marketIndexTrend.diffYesterday > 0 ? 'text-red' : 'text-green'">{{
                  summery.marketIndexTrend.broadMarketIndex
                }}</span>
                <span :class="summery.marketIndexTrend.diffYesterday > 0 ? 'text-red' : 'text-green'"
                  >{{ needPlus(summery.marketIndexTrend.diffYesterdayRatio) }}{{ summery.marketIndexTrend.diffYesterdayRatio.toFixed(2) }}% ({{
                    needPlus(summery.marketIndexTrend.diffYesterday)
                  }}
                  {{ summery.marketIndexTrend.diffYesterday }})</span
                >
              </div>
            </div>
            <div class="w-180">
              <indexChart
                :id="`chart-marketIndexTrend`"
                :data="summery?.marketIndexTrend.historyMarketIndexList"
                :direction="summery.marketIndexTrend.riseFallType === 'UP' ? 1 : 0"
                :x-key="0"
                :y-key="1"
                :class-name="'newindex-chart'"
                :last-day-end="true"
              ></indexChart>
            </div>
          </div>
          <div class="vertical-divider h-70"></div>
          <div class="flex w-400">
            <div>
              <div class="mb-12 flex items-center">
                <i class="iconfont icon-shichangqingxu text-20 text-gray-light"></i>
                <span class="mx-5 text-16 font-[500]">{{ $t('市场情绪') }}</span>
                <mytooltip :class="'w-350'" placement="bottom">
                  <i class="iconfont icon-kongxinwenhao text-18"></i>

                  <template #content>
                    <div class="py-15 text-14 text-gray-light">
                      <p>{{ $t('通过近期市场交易热度所计算的分数。') }}</p>
                      <p>{{ $t('结果区间为0~100，越高代表市场越活跃，越低代表则越低迷。') }}</p>
                    </div>
                  </template>
                </mytooltip>
                <div class="ml-10 flex items-center justify-center rounded-[4px] px-5 text-white" :class="getTopClass('transPerformanceTrend')">
                  <i v-if="summery.transPerformanceTrend.riseFallType === 'UP'" class="iconfont icon-shangsheng1 text-12 text-white"></i>
                  <i v-if="summery.transPerformanceTrend.riseFallType === 'DOWN'" class="iconfont icon-xiajiang text-12 text-white"></i>
                  <span class="text-white">{{ getTopText('transPerformanceTrend') }}</span>
                </div>
              </div>
              <div class="text-red">
                <span class="mr-5 text-26 font-[500]" :class="summery.transPerformanceTrend.diffYesterdayScore > 0 ? 'text-red' : 'text-green'"
                  >{{ summery.transPerformanceTrend.performanceScore }}{{ $t('分') }}</span
                >
                <span :class="summery.transPerformanceTrend.diffYesterdayScore > 0 ? 'text-red' : 'text-green'"
                  >{{ needPlus(summery.transPerformanceTrend.diffYesterdayScore) }} {{ summery.transPerformanceTrend.diffYesterdayScore }}</span
                >
              </div>
            </div>
            <div class="w-180">
              <div id="chart-transPerformanceTrend" class="h-70 w-180"></div>
            </div>
          </div>
        </div>
        <div class="overflow-hidden rounded-[10px]">
          <ClientOnly>
            <newIndexChart v-if="!chartLoading"></newIndexChart>
          </ClientOnly>
          <el-skeleton :loading="chartLoading" style="width: 900px">
            <template #template>
              <div class="flex w-full justify-center">
                <el-skeleton-item variant="ract" style="width: 900px; height: 500px" />
              </div>
            </template>
          </el-skeleton>
        </div>
      </div>
      <itemChangeList :change-data="itemChangeData.list"></itemChangeList>
    </div>
    <div class="right">
      <div class="mb-10">
        <online :onlinesummery="summery.onlinePlayerTrend"></online>
      </div>
      <div class="mb-10">
        <itemcompare></itemcompare>
      </div>
      <div class="mb-10">
        <transication></transication>
      </div>
      <div>
        <updateHistory :info="itemChangeData.version"></updateHistory>
      </div>
    </div>
  </div>
</template>

<script setup>
import indexChart from '~/pages/indexcomponents/indexChart.vue'
import newIndexChart from './indexcomponents/new-index-chart.vue'
import itemChangeList from './indexcomponents/item-change-list.vue'
import online from './indexcomponents/online.vue'
import transication from './indexcomponents/transication.vue'
import itemcompare from './indexcomponents/itemcompare.vue'
import updateHistory from './indexcomponents/update-history.vue'
import mytooltip from '~/components/common/mytooltip.vue'
import { newIndexBorderData, newIndexItemChange } from '~/api/steam'
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent, DataZoomComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import { use } from 'echarts'
import dayjs from 'dayjs'

const { t } = useI18n()
const $t = t
const moneyUnit = ref(monetaryUnit())

const chartLoading = ref(true)

const summeryData = ref({})
const itemChangeData = ref({})
console.log('time1', new Date().getTime())
const [{ data: summery }, { data: itemChange }] = await Promise.all([newIndexBorderData(), newIndexItemChange()])
console.log('time2', new Date().getTime())

useHead({
  title: $t('SteamDT-CS2饰品数据_CS2市场大盘_CS2饰品市场_CS2市场情绪-新果科技'),
  meta: [
    {
      name: 'description',
      content: $t('SteamDt为您提供CS2饰品的趋势分析、全网比价、成交数据、库存快照数据、市场情绪、饰品异动、挂刀比例及价格走势等数据，更多CS2饰品数据就上SteamDt。')
    },
    {
      name: 'keywords',
      content: $t('CS2市场, CSGO市场, CS2饰品市场,CS2市场大盘,CS2市场大盘数据分析,CS2饰品数据,CS2饰品趋势榜,CS2饰品涨跌榜,CS2市场情绪,CS2成交数据,CS饰品异动')
    }
  ]
})

summeryData.value = summery
summeryData.value.marketIndexTrend.historyMarketIndexList = summeryData.value.marketIndexTrend.historyMarketIndexList.sort((a, b) => a[0] - b[0])
summeryData.value.transPerformanceTrend.history = summeryData.value.transPerformanceTrend.history.sort((a, b) => a[0] - b[0])
itemChangeData.value = itemChange

let myChart = null
let echartOption = ref(null)

onMounted(() => {
  echartInit('chart-transPerformanceTrend')
  setTimeout(() => {
    chartLoading.value = false
  }, 500)
})

const getTopText = type => {
  if (summeryData.value[type].riseFallType === 'UP') {
    if (summeryData.value[type].riseFallDays === 1) {
      return $t('已涨1天')
    } else {
      return $t('连涨') + summeryData.value[type].riseFallDays + $t('天')
    }
  } else if (summeryData.value[type].riseFallType === 'DOWN') {
    if (summeryData.value[type].riseFallDays === 1) {
      return $t('已跌1天')
    } else {
      return $t('连跌') + summeryData.value[type].riseFallDays + $t('天')
    }
  } else {
    return $t('持平')
  }
}
const getTopClass = type => {
  if (summeryData.value[type].riseFallType === 'UP') {
    return 'bg-red'
  } else if (summeryData.value[type].riseFallType === 'DOWN') {
    return 'bg-green'
  } else {
    return 'bg-gray'
  }
}

const needPlus = num => {
  if (num > 0) {
    return '+'
  } else {
    return ''
  }
}

const echartInit = id => {
  echarts.use([TooltipComponent, GridComponent, DataZoomComponent, LineChart, CanvasRenderer, SVGRenderer])

  const chartDom = document.getElementById(`${id}`)
  const dataList = summeryData.value.transPerformanceTrend.history.map(item => {
    return [item[0] * 1000, item[1]]
  })
  console.log('dataList', dataList)
  myChart = echarts.init(chartDom, null, {
    renderer: 'svg'
  })
  echartOption.value = {
    grid: {
      // top: 60,
      left: 10
      // right: '20px'
    },
    // legend: {
    //   data: ['日线', 'MA7', 'MA14', 'MA21'],
    //   top: 15,
    //   right: 20,
    //   textStyle: {
    //     color: '#979CAE'
    //   }
    // },
    tooltip: {
      trigger: 'axis'
    },
    textStyle: {
      fontSize: 14,
      color: '#979CAE'
    },
    xAxis: [
      {
        type: 'time',
        show: false,
        boundaryGap: false
      }
    ],
    yAxis: [
      {
        type: 'value',
        boundaryGap: [0, '10%'],
        min: 'dataMin',
        show: false
      }
    ],

    series: [
      {
        name: $t('市场情绪'),
        type: 'line',
        smooth: false,
        showSymbol: false,
        symbol: 'circle',
        smooth: 0.2,
        symbolSize: 10,
        lineStyle: { width: 1 },
        color: '#F87600',
        areaStyle: {
          color: '#F87600',
          opacity: 0.05
        },
        // emphasis: {
        //   focus: 'series'
        // },
        data: dataList
      }
    ]
  }
  echartOption.value && myChart.setOption(echartOption.value)
}
</script>

<style lang="scss">
.index-container {
  margin-top: -230px;
  .vertical-divider {
    width: 1px;
    // height: auto;
    background-color: #eff1f5;
  }
}
</style>
